import { Link } from "react-router-dom"
import { useTransition, animated, config } from 'react-spring'
import "../styles/Navigation.css"


export default function Navigation({ isMenuOpen }) {

  const transition = useTransition(isMenuOpen, {
    from: { x: 0, y: -500, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: { x: 0, y: -500, opacity: 0 },
    config: config.stiff
})

    return (
      <>
        {transition((style, isMenuOpen) => isMenuOpen ?
            <animated.nav style={style}>
            <ul>
              <li>
                <Link to="/introduction">Bemutatkozás</Link>
              </li>
              <li>
                <Link to="/menu">Étlap</Link>
              </li>
              <li>
                <Link to="/winelist">Borlap</Link>
              </li>
              <li>
                <Link to="/menu_with_pictures">Képes Étlap</Link>
              </li>
              <li>
                <Link to="/gallery">Galéria</Link>
              </li>
              <li>
                <Link to="/articles">Rólunk Írták</Link>
              </li>
              <li>
                <Link to="/contact">Kapcsolat</Link>
              </li>
            </ul>
            <ul className="english_nav">
              <li>
                <Link to="/introduction">Introduction</Link>
              </li>
              <li>
                <Link to="/menu">Menu</Link>
              </li>
              <li>
                <Link to="/winelist">Wine List</Link>
              </li>
              <li>
                <Link to="/menu_with_pictures">Menu with Pictures</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/articles">Our Famous Guests</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </animated.nav>
        : "" )}
      </>
    )
}