import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core'
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-flip/effect-flip.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "../styles/Swiper.css"

/* import img01 from "../gallery/paprika_jancsi_etterem_01.JPG"
import img03 from "../gallery/paprika_jancsi_etterem_03.JPG"
import img04 from "../gallery/paprika_jancsi_etterem_04.JPG"
import img05 from "../gallery/paprika_jancsi_etterem_05.JPG"
import img06 from "../gallery/paprika_jancsi_etterem_06.JPG"
import img07 from "../gallery/paprika_jancsi_etterem_07.JPG"
import img08 from "../gallery/paprika_jancsi_etterem_08.JPG"
import img09 from "../gallery/paprika_jancsi_etterem_09.JPG"
import img10 from "../gallery/paprika_jancsi_etterem_10.JPG"
import img11 from "../gallery/paprika_jancsi_etterem_11.JPG"
import img12 from "../gallery/paprika_jancsi_etterem_12.JPG" */
import img13 from "../gallery/goulash_soup_gulyas_leves_bogracsban.jpg"
import img14 from "../gallery/dupla_steak_grillezett zoldsegekkel.jpg"
import img15 from "../gallery/csulok_pekne_modra.jpg"
import img16 from "../gallery/szuzermek_budapest_modra.jpg"
import img17 from "../gallery/parajos_tagliattelle.jpg"


export default function Slider() {

  const alts = {
    "street": "Paprika Jancsi étterem utcafront / Papria Jancsi Restaurant street view",
    "interior": "Paprika Jancsi étterem belső tér / Paprika Jancsi Restaurant interior"
  }

SwiperCore.use([Pagination,Navigation,Autoplay]);

  return (

    <Swiper
      direction={'horizontal'}
      spaceBetween={5}
      slidesPerView={'1'}
      loop={true}
      loopedSlides={100}
      autoplay={{disableOnInteraction: false, delay: 3000}}
/*       onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)} */
      breakpoints={{
        // when window width is < 640px
        740: {
          width: 740,
          slidesPerView: 2,
        },
        // when window width is < 768px
        990: {
          width: 990,
          slidesPerView: 2,
        },
      }}
    >
      <SwiperSlide>
        <div className="menu_names">
          <h3>Gulyásleves bográcsban</h3>
          <h4>Cauldron of Hungarian goulash soup with beef</h4>
        </div>
        <img src={img13} alt={'goulash soup gulyásleves bográcsban'} />
      </SwiperSlide>
      <SwiperSlide>
        <div className="menu_names">
          <h3>Dupla steak grillezett zöldségekkel</h3>
          <h4>Double steak with steak grilled vegetables</h4>
        </div>
        <img src={img14} alt={'dupla steak grillezett zöldségekkel'} />
      </SwiperSlide>
      <SwiperSlide>
        <div className="menu_names">
          <h3>Kemencében sült csülök pékné módra</h3>
          <h4>Roasted knuckle of pork and roast potato slices</h4>
        </div>
        <img src={img15} alt={'Kemencében sült csülök pékné módra'} /></SwiperSlide>
      <SwiperSlide>
        <div className="menu_names">
          <h3>Szűzérmék Budapest módra</h3>
          <h4>Grilled pork in Budapest style with potato chips</h4>
        </div>
        <img src={img16} alt={'Szűzérmék Budapest módra'} />
      </SwiperSlide>
      <SwiperSlide>
        <div className="menu_names">
          <h3>Parajos tagliatelle szárított paradicsommal</h3>
          <h4>Tagliatelle with dried tomato and spinach</h4>
        </div>
        <img src={img17} alt={'Parajos tagliattelle szárított paradicsommal'} />
      </SwiperSlide>
    </Swiper>
  )
}