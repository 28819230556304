import "../styles/Footer.css"

export default function Footer() {
    return (
        <footer>
            <section data-aos="fade-right" data-aos-anchor-placement="footer">
                <h2>Napi Menü / Daily Menu:</h2>
                <p>11:30 - 14 óráig / 11:30 am - 2 pm</p>
                <p>Ára / Price: 1780 Ft</p>
                <p><script type="text/javascript" src="http://www.napimenu.eu/tnm.php?id=24&cp=utf-8"></script></p>
            </section>
            <section data-aos="fade-up" data-aos-anchor-placement="footer">
                <h2>Kapcsolat / Contact:</h2>
                <p>Cím / Address: Budapest, Ráday u. 16</p>
                <p>Asztalfoglalás / Reservation: <a href="tel:+3612990702">+36 1 299 07 02</a></p>
                <p>Email: <a href="mailto:info@paprikajancsietterem.hu">info@paprikajancsietterem.hu</a></p>
            </section>
            <section data-aos="fade-left" data-aos-anchor-placement="footer">
                <h2>Nyitva tartás / Opening Hours:</h2>
                <p>11 - 24 óráig / 11 am - 12 pm</p>
            </section>
        </footer>
    )
}