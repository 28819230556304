import { Helmet } from "react-helmet"
import { useEffect } from "react"
import useViewportWidth from "../../hooks/useViewportWidth"

export default function Introduction({ setIsMenuOpen, setShowReservBtn }) {
    const viewportWidth = useViewportWidth()

    useEffect(() => {
        if (viewportWidth < 990) {
            setIsMenuOpen(false)
        }
        setShowReservBtn(true)
    }, [])
    return (
        <div>
            <Helmet>
                <title>Bemutatkozás / Introduction</title>
                <meta name="desription" content="Az étterem rövid bemutatása / Short introduction of the restaurnat" />
            </Helmet>            
            <div>
                <h2>Beumtatkozás:</h2>
                <h5>Egy magyaros étterem Budapest közepén, ínyenceknek és a házias ízek kedvelőinek…</h5>
                <p>Romantikus vacsorákhoz, meghitt családi beszélgetésekhez és népes
                    rendezvényekhez egyaránt ideális színteret biztosít a Ráday utcában üzemelő
                    Paprika Jancsi Étterem.</p>
                <p>
                    Az étterem elegáns és hangulatos belső tere mellett, a jó idő beálltával a több mint
                    50 férőhelyes teraszon várja évről évre a visszatérő és új vendégeit.
                </p>
                <p>
                    Étlapunk összeállításánál a klasszikus magyar konyha remekeire koncentráltunk,
                    ezzel is ápolva a hagyományokat.
                </p>
                <p>
                    Ételeink között megtalálhatóak az egyszerűbb magyaros ételek, de aki ennél
                    különlegesebbre vágyik, választhat libamáj, bélszín, szűz, vad vagy hal
                    ételkülönlegességeinkből.
                </p>
                <p>
                    A Paprika Jancsi Étterem hangulatos különtermével, szolgáltatásaival ideális
                    helyszín családi, baráti összejövetelek, ballagások, kisebb esküvők és céges
                    rendezvények megtartására.
                </p>
                <p>
                   Legyen a Vendégünk, ízlelje meg Ön is hagyományos, minőségi ételeinket! 
                </p>
                <br />
                <p>
                    Várjuk Szeretettel!
                </p>
                <br/><hr/><br/>
                <h2>Introduction:</h2>
                <h5>
                    One of the best Hungarian restaurant in the center of Budapest for those who
                    keen on the savoury, home made hungarian cuisine.
                </h5>
                <p>Paprika Jancsi Hungarian Restauran is located in the Raday street. It is ideal place
                    for romantic dinner, cosy family events and suitable for bigger groups as well.</p>
                <p>It has elegant and wellcoming interior decorated with hungarian motives. When the
                    weather turns warmer the outside terrace is also available which can accomodate
                    more than 50 guests.</p>
                <p>Our menu contains the wide selection of the hungarian dishes, covered every local food demands, and traditions.</p>
                <p>In the selection you can finde simple local food but if you are a really gourmand you
                    can choose amoung the goose liver , supreme chicken ,tender beef, pork medallion
                    or local fish dishes which are created by the best hungarian chefs. Of course
                    everyone needs to try the best Hungarian goulash soup and traditional stews made
                    from chicken, beef, fish or wildboar.</p>
                <p>Paprika Jancsi Hungarian restaurant has a cosy private room where 50 people can
                    be seated. It is recomended for family events, company meetings, weddings,
                    banquets, graduations.</p>
                <p>Please be our guest, we can provide you the best taste of the local coulinary art and
                    the best service overall.</p>
                <br />
                <p>We are looking forward seeing you!</p>
            </div>
        </div>
    )
}