import { useState, useRef, useEffect } from 'react'
import { send } from 'emailjs-com';
import '../../styles/Reservation.css'
import toast from "react-hot-toast"
import env from "react-dotenv";
import { useHistory } from "react-router-dom";


export default function Reservation({ setShowReservBtn }) {

    const service = env.REACT_APP_SERVICE
    const template = env.REACT_APP_TEMPLATE
    const key = env.REACT_APP_KEY

    let history = useHistory();

    useEffect(() => {
        setShowReservBtn(false)
    }, [])
    

    const form = useRef()

    const [name, setName] = useState("")
    const [people, setPeople] = useState(1)
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [text, setText] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    function handleSubmit(e) {
        e.preventDefault() 
        const data = { people, date, time, name, email, tel, text }
        setIsLoading(true)
        send(service, template, data, key)
        .then(() => {
            setIsLoading(false)
            toast.success(`Asztalfoglalás sikeresen elküldve!`, {
                style: {
                    padding: '1rem',
                    fontSize: '1.3rem'
                },
            })
            history.push('/')
        }, (error) => {
            setIsLoading(false)
            toast.error(`Nem sikerült elküldeni a foglalást: ${error.text}`);
        });
        
        setName("")
        setPeople(1)
        setDate("")
        setTime("")
        setEmail("")
        setTel("")
        setText("")
    }
    
    if (isLoading) return (
        <>
            <h2 className='loader'>Adatok beküldése folyamatban...&#8987;</h2>
            <h3 className='loader'>Loading...&#8987;</h3>
        </>
    )

    return (
        <div className='reservation_form_wrapper'>
            <h2>A * által jelölt mezők kitöltése kötelező</h2>
            <h3>Required fields marked with *</h3>
            <form ref={form} onSubmit={handleSubmit}>
                <label htmlFor="name-input">*Milyen névre: || Name:</label>
                <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    name="name-input"
                    placeholder="Név / Name"
                    required
                />
                <label htmlFor="people-input">*Hány főre: || How many people:</label>
                <input
                    value={people}
                    onChange={(e) => setPeople(e.target.value)}
                    type="number"
                    name="people-input"
                    placeholder="Hány főre / How many people"
                    min={1}
                    max={40}
                    required
                />
                <h3>Kedves vendégeink! December 24-től 26-ig nem áll módunkban asztalfoglalást rögzíteni.</h3>
                <label htmlFor="date-input">*Dátum: || Date:</label>
                <input
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    type="date"
                    name="date-input"
                    placeholder="Dátum / Date"
                    required
                />
                <label htmlFor="date-input">*Időpont: || Time:</label>
                <input
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    type="time"
                    name="time-input"
                    placeholder="Időpont / Time"
                    required
                />
                <label htmlFor="email-input">*E-mail cím: || E-mail address:</label>
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="email-input"
                    placeholder="E-mail cím / E-mail address"
                    required
                />
                <label htmlFor="tel-input">*Telefon: || Phone:</label>
                <input
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                    type="tel"
                    name="tel-input"
                    placeholder="Telefon / Phone"
                    required
                />
                <label htmlFor="text-input">Megjegyzés: || Comment:</label>
                <textarea
                    cols="40"
                    rows="5"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    name="text-input"
                    placeholder="Megjegyzés / Comment"
                />
                
                <button type="submit">
                    <h3 className='booking_btn'>Asztalt foglalok</h3>
                    <h3>Book a table</h3>
                </button>
            </form>
        </div>
    )
}