import { Helmet } from "react-helmet"
import { useEffect } from "react"
import useViewportWidth from "../../hooks/useViewportWidth"

export default function Menu({ setIsMenuOpen, setShowReservBtn }) {
    const viewportWidth = useViewportWidth()

    useEffect(() => {
        if (viewportWidth < 990) {
            setIsMenuOpen(false)
        }
        setShowReservBtn(true)
    }, [])
    const url = "https://drive.google.com/file/d/10EieTqqx7rBRM1iQxZXpBZKIR0rFVBKu/preview"
    return (
        <div>
            <Helmet>
                <title>Étlap / Menu</title>
                <meta name="desription" content="Megtekintheti Étlapunkat / You can browse our Menu" />
            </Helmet> 
            <iframe src={url} title="menu" allow="autoplay"></iframe>
        </div>
    )
}