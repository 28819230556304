import "../../styles/Contact.css"
import { Helmet } from "react-helmet"
import { useEffect } from "react"
import useViewportWidth from "../../hooks/useViewportWidth"

export default function Contact({ setIsMenuOpen, setShowReservBtn }) {
    const viewportWidth = useViewportWidth()

    useEffect(() => {
        if (viewportWidth < 990) {
            setIsMenuOpen(false)
        }
        setShowReservBtn(true)
    }, [])

    return ( 
        <div>
            <Helmet>
                <title>Kapcsolat / Contact</title>
                <meta name="desription" content="Így tudsz kapcsolatba lépni velünk / How to contact us" />
            </Helmet>
            <div>
                <div className="contact_container">
                    <section>
                        <h2>Kapcsolat / Contact:</h2>
                        <p>Cím / Address: Budapest, Ráday u. 16</p>
                        <p>Asztalfoglalás / Reservation: <a href="tel:+3612990702">+36 1 299 07 02</a></p>
                        <p>Email: <a href="mailto:info@paprikajancsietterem.hu">info@paprikajancsietterem.hu</a></p>
                    </section>
                    <section>
                        <h2>Nyitva tartás / Opening Hours:</h2>
                        <p>11 - 24 óráig / 11 am - 12 pm</p>
                    </section>
                </div>
                <div class="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2696.115464255062!2d19.060864151384397!3d47.48766397907485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc5a6fa20663%3A0xb91a5c6e30344bdf!2sPaprika%20Jancsi%20%C3%89tterem!5e0!3m2!1shu!2shu!4v1617888902567!5m2!1shu!2shu"
                    title="map_to_paprikajancsi" allowfullscreen=""></iframe>
                </div>
            </div>
        </div>
    )
}