import "../../styles/Articles.css"
import { Helmet } from "react-helmet"
import { useEffect } from "react"
import useViewportWidth from "../../hooks/useViewportWidth"

export default function Articles({ setIsMenuOpen, setShowReservBtn }) {
    const viewportWidth = useViewportWidth()

    useEffect(() => {
        if (viewportWidth < 990) {
            setIsMenuOpen(false)
        }
        setShowReservBtn(true)
    }, [])

    return (
        <div>
            <Helmet>
                <title>Rólunk írták / Our famous guests</title>
                <meta name="desription" content="Újságmegjelenések híres vendégeinkkel" />
            </Helmet>
            <div className="articles_container">
                <ul>
                    <li><a href="https://funzine.hu/hu/7-magyaros-etterem-ami-meg-a-nagymamankat-is-leelozi/" >7 magyaros étterem, ami még a nagymamánkat is leelõzi</a></li>
                    <li><a href="http://www.funzine.hu/hu/2016-09-7-magyaros-etterem-ami-meg-a-nagymamankat-is-leelozi" >Bíró Kati kitalálta, Visegrádi Raul Kristóf megkomponálja</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/gesztesi.pdf" >Koccintó Gesztesi Károllyal</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/anton_molnar.pdf" >Koccintó Anton Molnárral</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/fesztbaum_bela.pdf" >Koccintó Fesztbaum Bélával</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/bestofbudapest.pdf" >Best of Budapest</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/rakoczi_ferenc.pdf" >Koccintó Rákóczi Ferenccel</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/lukats_andor.pdf" >Koccintó Lukáts Andorral</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/40-41.pdf" >Koccintó Domahidy Lászlóval</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/32.pdf" >Sztárkoccintó Aradszky Lászlóval</a></li>
                </ul>
                <ul>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/jaszsagi_fozoverseny.pdf" >2014. VI. Jászsági népi ételek fõzõversenye Bollók Feri emlékére</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/szipal.pdf" >Koccintó Szipál Martinnal</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/35.pdf" >Kétéves a Lizard Étterem</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/lizard_erzes.pdf" >&quot;Lizard érzés&quot; a Ráday utcában</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/honaptema.pdf" >Egészségtrend</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/liz_04_1.pdf" >Sztárkoccintó Nádas Györggyel</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/liz_07_1.pdf" >Koccintó Szõcs Artúrral</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/liz_etterem.pdf" >Gasztrosziget a Ráday utcában</a></li>
                    <li><a href="http://www.paprikajancsietterem.hu/sajto/30.pdf" >Sztárkoccintó Csuja Imrével</a></li>
                </ul>
            </div>
        </div>
    )
}