import { Link } from "react-router-dom"
import logo from "../imgs/logo.png"
import "../styles/Header.css"

export default function Header({ handleClick, showHamburger }) {

    return (
        <header>
            <Link to="/"><img className='logo' src={logo} alt="Logo"/></Link>  
            <span className="header_bgr"></span>
            <span className='address'>Budapest, Ráday u. 16.</span>
            <svg onClick={handleClick} 
                style={{display: showHamburger? "block" : "none"}}
                className="hamburger_menu_btn" 
                width="40"
                height="36"
                viewBox="0 0 36 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M0 0.5H36V5.5H0V0.5Z" fill="#e41819"/>
                <path d="M0 13.5H36V18.5H0V13.5Z" fill="#e41819"/>
                <path d="M36 26.5H0V31.5H36V26.5Z" fill="#e41819"/>
            </svg>
        </header>
    )
}