import Header from "./components/Header"
import Navigation from "./components/Navigation"
import Main from "./components/Main"
import Footer from "./components/Footer"
import { useEffect, useState } from "react"
import AOS from "aos"
import "aos/dist/aos.css"
import useViewportWidth from "./hooks/useViewportWidth"
import Slider from "./components/Slider"
import { Link } from "react-router-dom"
import { Toaster } from "react-hot-toast"

function App() {

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [showHamburger, setShowHamburger] = useState(true)
  const [showReservBtn, setShowReservBtn] = useState(true)
  const viewportWidth = useViewportWidth()
  
  function handleClick() {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(()=>{
    if (viewportWidth >= 990) {
      setIsMenuOpen(true)
      setShowHamburger(false)
    } else {
      setIsMenuOpen(false)
      setShowHamburger(true)
    }
  }, [])

  useEffect(() => {
    AOS.init({
      duration: 500,
      startEvent: 'load'
    })
    AOS.refresh()
  }, [])

  return (
    <>
      <Header handleClick={handleClick} showHamburger={showHamburger}/>
      <Navigation isMenuOpen={isMenuOpen}/>
      <Slider />
      <div><Toaster /></div>
      {showReservBtn && <Link to="/reservation" className="reservation_btn_wrapper">
        <button className="reservation_btn">
          <h3 className='booking_btn'>Asztalt foglalok</h3>
          <h3>Book a table</h3></button>
        </Link>}
      <Main setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
      <Footer/>
    </>
  )
}

export default App
