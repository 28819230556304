import "../../styles/Gallery.css"
import { Helmet } from "react-helmet"
import img03 from "../../gallery/paprika_jancsi_etterem_03.JPG"
import img04 from "../../gallery/paprika_jancsi_etterem_04.JPG"
import img05 from "../../gallery/paprika_jancsi_etterem_05.JPG"
import img06 from "../../gallery/paprika_jancsi_etterem_06.JPG"
import img07 from "../../gallery/paprika_jancsi_etterem_07.JPG"
import img08 from "../../gallery/paprika_jancsi_etterem_08.JPG"
import img09 from "../../gallery/paprika_jancsi_etterem_09.JPG"
import img10 from "../../gallery/paprika_jancsi_etterem_10.JPG"
import img11 from "../../gallery/paprika_jancsi_etterem_11.JPG"
import img12 from "../../gallery/paprika_jancsi_etterem_12.JPG"

import { useEffect } from "react"
import useViewportWidth from "../../hooks/useViewportWidth"

export default function Gallery({ setIsMenuOpen, setShowReservBtn }) {
    const viewportWidth = useViewportWidth()

    useEffect(() => {
        if (viewportWidth < 990) {
            setIsMenuOpen(false)
        }
        setShowReservBtn(true)
    }, [])

    const alts = {
        "street": "Paprika Jancsi étterem utcafront / Papria Jancsi Restaurant street view",
        "interior": "Paprika Jancsi étterem belső tér / Paprika Jancsi Restaurant interior"
    }

    return (
        <div>
            <Helmet>
                <title>Galéria / Image Gallery</title>
                <meta name="desription" content="Képek az étteremről / Pohotos about the restaurant" />
            </Helmet>
            <div className="img_container">
                <img src={img03} loading="lazy" alt={alts.street} />
                <img src={img04} loading="lazy" alt={alts.street} />
                <img src={img12} loading="lazy" alt={alts.interior} />
                <img src={img11} loading="lazy" alt={alts.interior} data-aos="flip-left"/>
                <img src={img05} loading="lazy" alt={alts.street} data-aos="flip-right"/>
                <img src={img06} loading="lazy" alt={alts.street} data-aos="flip-left"/>
                <img src={img07} loading="lazy" alt={alts.interior} data-aos="flip-right"/>
                <img src={img08} loading="lazy" alt={alts.interior} data-aos="flip-left"/>
                <img src={img09} loading="lazy" alt={alts.interior} data-aos="flip-right"/>
                <img src={img10} loading="lazy" alt={alts.interior} data-aos="flip-left"/>
            </div>
        </div>
    )
}