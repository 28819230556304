import CovidStatement from "./pages/CovidStatement"
import Introduction from "./pages/Intoduction"
import Menu from "./pages/Menu"
import WineList from "./pages/WineList"
import MenuWithPictures from "./pages/MenuWithPictures"
import Gallery from "./pages/Gallery"
import Articles from "./pages/Articles"
import Contact from "./pages/Contact"
import { Switch, Route } from "react-router"
import "../styles/Main.css"
import Reservation from "./pages/Reservation"

export default function Main({ setIsMenuOpen, setShowReservBtn }) {
    return (
        <main>
            <Switch>
                <Route path="/introduction">
                    <Introduction setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
                <Route path="/menu">
                    <Menu setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
                <Route path="/winelist">
                    <WineList setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
                <Route path="/menu_with_pictures">
                    <MenuWithPictures setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
                <Route path="/gallery">
                    <Gallery setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
                <Route path="/articles">
                    <Articles setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
                <Route path="/contact">
                    <Contact setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
                <Route path="/reservation">
                    <Reservation setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
                <Route path="/">
                    <Contact setIsMenuOpen={setIsMenuOpen} setShowReservBtn={setShowReservBtn} />
                </Route>
            </Switch>
        </main>
    )
}